	/*
  	Flaticon icon font: Flaticon
  	Creation date: 15/04/2020 05:48
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-dryer:before { content: "\f100"; }
.flaticon-television:before { content: "\f101"; }
.flaticon-bed:before { content: "\f102"; }
.flaticon-bath:before { content: "\f103"; }
.flaticon-idea:before { content: "\f104"; }
.flaticon-lamp:before { content: "\f105"; }
.flaticon-fridge:before { content: "\f106"; }
.flaticon-flame:before { content: "\f107"; }
.flaticon-microwave-oven:before { content: "\f108"; }
.flaticon-maid:before { content: "\f109"; }
.flaticon-spanner:before { content: "\f10a"; }
.flaticon-furniture-and-household:before { content: "\f10b"; }
.flaticon-wine:before { content: "\f10c"; }
.flaticon-smoke:before { content: "\f10d"; }
.flaticon-mug:before { content: "\f10e"; }
.flaticon-steak:before { content: "\f10f"; }
.flaticon-phone:before { content: "\f110"; }
.flaticon-plug:before { content: "\f111"; }
.flaticon-bellhop:before { content: "\f112"; }
.flaticon-iron:before { content: "\f113"; }
.flaticon-lift:before { content: "\f114"; }
.flaticon-bed-1:before { content: "\f115"; }
.flaticon-spa:before { content: "\f116"; }
.flaticon-hotel:before { content: "\f117"; }
.flaticon-pool:before { content: "\f118"; }
.flaticon-strongbox:before { content: "\f119"; }
.flaticon-key:before { content: "\f11a"; }
.flaticon-euro:before { content: "\f11b"; }
.flaticon-towel:before { content: "\f11c"; }
.flaticon-taxi:before { content: "\f11d"; }
.flaticon-dog:before { content: "\f11e"; }
.flaticon-man:before { content: "\f11f"; }
.flaticon-slipper:before { content: "\f120"; }
.flaticon-smartphone:before { content: "\f121"; }
.flaticon-menu:before { content: "\f122"; }
.flaticon-clock:before { content: "\f123"; }
.flaticon-chair:before { content: "\f124"; }
.flaticon-doctor:before { content: "\f125"; }
.flaticon-gym:before { content: "\f126"; }
.flaticon-phone-1:before { content: "\f127"; }
.flaticon-lock:before { content: "\f128"; }
.flaticon-wifi:before { content: "\f129"; }
.flaticon-battery:before { content: "\f12a"; }
.flaticon-shower:before { content: "\f12b"; }
.flaticon-kettle:before { content: "\f12c"; }
.flaticon-restroom:before { content: "\f12d"; }
.flaticon-faucet:before { content: "\f12e"; }
.flaticon-bed-2:before { content: "\f12f"; }
.flaticon-bug:before { content: "\f130"; }
.flaticon-mirror:before { content: "\f131"; }
.flaticon-housework:before { content: "\f132"; }
.flaticon-chair-1:before { content: "\f133"; }
.flaticon-lamp-1:before { content: "\f134"; }
.flaticon-remote-control:before { content: "\f135"; }
.flaticon-iron-1:before { content: "\f136"; }
.flaticon-fan:before { content: "\f137"; }
.flaticon-desk:before { content: "\f138"; }
.flaticon-window:before { content: "\f139"; }
.flaticon-sofa:before { content: "\f13a"; }
.flaticon-frame:before { content: "\f13b"; }
.flaticon-payment:before { content: "\f13c"; }
.flaticon-spray:before { content: "\f13d"; }
.flaticon-toilet-paper-roll-outline:before { content: "\f13e"; }
.flaticon-bell:before { content: "\f13f"; }
.flaticon-checklist:before { content: "\f140"; }
.flaticon-air-conditioner:before { content: "\f141"; }
.flaticon-mite:before { content: "\f142"; }
.flaticon-furniture-and-household-1:before { content: "\f143"; }
.flaticon-pillow:before { content: "\f144"; }
.flaticon-bed-3:before { content: "\f145"; }
.flaticon-hotel-1:before { content: "\f146"; }
.flaticon-plug-1:before { content: "\f147"; }
.flaticon-plug-2:before { content: "\f148"; }
.flaticon-furniture-and-household-2:before { content: "\f149"; }
.flaticon-curtain:before { content: "\f14a"; }
.flaticon-shampoo:before { content: "\f14b"; }
.flaticon-work:before { content: "\f14c"; }
.flaticon-entomology:before { content: "\f14d"; }
.flaticon-trash:before { content: "\f14e"; }
.flaticon-door:before { content: "\f14f"; }
.flaticon-kitchen:before { content: "\f150"; }
.flaticon-window-1:before { content: "\f151"; }
.flaticon-shower-tray:before { content: "\f152"; }
.flaticon-drain:before { content: "\f153"; }
.flaticon-mirror-1:before { content: "\f154"; }
.flaticon-furniture-and-household-3:before { content: "\f155"; }
.flaticon-light:before { content: "\f156"; }
.flaticon-pipe:before { content: "\f157"; }
.flaticon-furniture-and-household-4:before { content: "\f158"; }
.flaticon-cupboard:before { content: "\f159"; }
.flaticon-cabinet:before { content: "\f15a"; }
.flaticon-furniture-and-household-5:before { content: "\f15b"; }
.flaticon-freezer:before { content: "\f15c"; }
.flaticon-toilet:before { content: "\f15d"; }
.flaticon-sensor:before { content: "\f15e"; }
.flaticon-furniture-and-household-6:before { content: "\f15f"; }
.flaticon-plumb:before { content: "\f160"; }
.flaticon-plumber:before { content: "\f161"; }
.flaticon-fan-1:before { content: "\f162"; }
.flaticon-towel-rail:before { content: "\f163"; }
.flaticon-pipe-1:before { content: "\f164"; }
.flaticon-shower-1:before { content: "\f165"; }
.flaticon-leak:before { content: "\f166"; }
.flaticon-access:before { content: "\f167"; }
.flaticon-hanger:before { content: "\f168"; }
.flaticon-hanger-1:before { content: "\f169"; }
.flaticon-lamp-2:before { content: "\f16a"; }
.flaticon-furniture-and-household-7:before { content: "\f16b"; }
.flaticon-alert:before { content: "\f16c"; }
.flaticon-fan-2:before { content: "\f16d"; }
.flaticon-lamp-3:before { content: "\f16e"; }
.flaticon-plug-3:before { content: "\f16f"; }
.flaticon-shower-2:before { content: "\f170"; }
.flaticon-umbrella:before { content: "\f171"; }
.flaticon-washer:before { content: "\f172"; }
.flaticon-dishes:before { content: "\f173"; }
.flaticon-cutlery:before { content: "\f174"; }
.flaticon-birthday-and-party:before { content: "\f175"; }
.flaticon-light-switch:before { content: "\f176"; }